import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 合同客户列表
export const getCustomerGroupInfoPage = (data) => {
    return request({
        url: apiCrmHost + '/gcrm/customer/query',
        method: 'post',
        data
    });
};
// 集体客户-新建客户推送
export const pushCustomerGroupInfo = (data) => {
    return request({
        url: apiCrmHost + '/gcrm/customer/push/page',
        method: 'post',
        data
    });
};
