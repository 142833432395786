import dayjs from 'dayjs';
const NO_INNER_CUST_FLAG = 'N'; // 否
const YES_LINK_MATCH_OMS = '1'; // 是
const pickerOptions = {
    shortcuts: [{
        text: '最近一周',
        onClick (picker) {
            const DAYS_A_WEEK = 7;
            const start = dayjs().startOf('day').subtract(DAYS_A_WEEK - 1, 'd').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick (picker) {
            const start = dayjs().startOf('day').subtract(1, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick (picker) {
            const start = dayjs().startOf('day').subtract(3, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }]
};
export const config = {
    name: 'customerGroup',
    searchFields: [
        {
            name: '集团编码',
            value: 'customerUnitCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '安得客户编码',
            value: 'anntoCustomerCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户名称',
            value: 'customerName',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '统一社会信用代码',
            value: 'uscc',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '安得打标标识',
            value: 'customerMatchStatusList',
            type: 'select',
            isFixed: true,
            multiple: true,
            optionNum: 'CRM_CUSTOMER_MATCH_STATUS',
            span: 3
        },
        {
            name: '标识更新日期',
            value: 'markTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            pickerOptions,
            startPlaceholder: '标识更新日期从',
            endPlaceholder: '标识更新日期到',
            span: 5
        },
        {
            name: '集团内部客户标识',
            value: 'innerCustFlag',
            type: 'select',
            isFixed: false,
            optionNum: 'SYS_CRM_YES_NO',
            span: 3
        },
        {
            name: '集团客户状态',
            value: 'statusCode',
            type: 'select',
            isFixed: false,
            optionNum: 'SYS_WM_CUSTOMER_STATUS',
            span: 3
        },
        {
            name: '订单客户匹配',
            value: 'linkMatchOms',
            type: 'select',
            isFixed: false,
            optionNum: 'SYS_CRM_YES_NO_NUMBER',
            span: 3
        },
        {
            name: '关联安得客户社信号匹配',
            value: 'linkMatchUscc',
            type: 'select',
            isFixed: false,
            optionNum: 'SYS_CRM_YES_NO_NUMBER',
            span: 3
        }
    ],
    selection: true,
    index: false,
    tableData: {
        columns: [
            {
                label: '集团编码',
                prop: 'customerUnitCode',
                sort: true,
                minWidth: 150
            },
            {
                label: '客户名称',
                prop: 'customerName',
                sort: true,
                minWidth: 150
            },
            {
                label: '客户简称',
                prop: 'commonName',
                sort: true,
                minWidth: 150
            },
            {
                label: '统一社会信用代码',
                prop: 'uscc',
                sort: true,
                minWidth: 150
            },
            {
                label: '法人代表',
                prop: 'corpRepresentative',
                sort: true,
                minWidth: 150
            },
            {
                label: '集团内部客户标识',
                prop: 'innerCustFlag',
                type: 'select',
                optionsKey: 'SYS_CRM_YES_NO',
                sort: true,
                minWidth: 150
            },
            {
                label: '集团客户状态',
                prop: 'statusCode',
                type: 'select',
                optionsKey: 'SYS_WM_CUSTOMER_STATUS',
                sort: true,
                minWidth: 150
            },
            {
                label: '关联的安得客户',
                prop: 'linkUnitCustomerCode',
                sort: true,
                minWidth: 150
            },
            {
                label: '安得社信号匹配',
                prop: 'linkUsccCustomerCode',
                minWidth: 150
            },
            {
                label: '订单客户匹配',
                prop: 'linkMatchOms',
                type: 'select',
                optionsKey: 'SYS_CRM_YES_NO_NUMBER',
                minWidth: 150
            },
            {
                label: '关联安得客户社信号匹配',
                prop: 'linkMatchUscc',
                type: 'select',
                optionsKey: 'SYS_CRM_YES_NO_NUMBER',
                minWidth: 150
            },
            {
                label: '安得标识',
                prop: 'customerMatchStatus',
                type: 'select',
                optionsKey: 'CRM_CUSTOMER_MATCH_STATUS',
                sort: true,
                minWidth: 150
            },
            {
                label: '订单客户匹配日期',
                prop: 'existOmsTime',
                minWidth: 150
            },
            {
                label: '打标更新日期',
                prop: 'markTime',
                sort: true,
                minWidth: 150
            },
            {
                label: '推送状态',
                prop: 'pushXsyStatus',
                type: 'select',
                optionsKey: 'CRM_PUSH_XSY_STATUS',
                minWidth: 150
            },
            {
                label: '推送失败信息',
                prop: 'pushXsyMsg',
                minWidth: 150
            },
            {
                label: '注册资本',
                prop: 'capital',
                minWidth: 150
            },
            {
                label: '国家',
                prop: 'country',
                minWidth: 150
            },
            {
                label: '省',
                prop: 'state',
                minWidth: 150
            },
            {
                label: '城市',
                prop: 'city',
                minWidth: 150
            },
            {
                label: '县',
                prop: 'county',
                minWidth: 150
            },
            // {
            //     label: '镇',
            //     prop: 'town',
            //     minWidth: 150
            // },
            {
                label: '注册地址',
                prop: 'registrationAddress',
                minWidth: 150
            },
            {
                label: '客户类型',
                prop: 'customerType',
                type: 'select',
                optionsKey: 'CRM_CUSTOMER_TYPE',
                minWidth: 150
            },
            {
                label: '纳税人类型',
                prop: 'taxpayerType',
                type: 'select',
                optionsKey: 'SYS_TAXER_TYPE',
                minWidth: 150
            },
            {
                label: '企业性质',
                prop: 'enterpriseNature',
                type: 'select',
                optionsKey: 'SYS_SUPPLIER_NATURE',
                minWidth: 150
            },
            {
                label: '主要电话号码',
                prop: 'mainPhone',
                minWidth: 150
            },
            {
                label: '组织机构代码',
                prop: 'nacao',
                minWidth: 150
            },
            {
                label: '企业营业执照注册号',
                prop: 'registrationNumber',
                minWidth: 150
            },
            {
                label: '纳税登记人',
                prop: 'taxRegistration',
                minWidth: 150
            },
            {
                label: '税务登记号',
                prop: 'taxRegNum',
                minWidth: 150
            },
            {
                label: '三证合一标记',
                prop: 'threeCerInOneFlg',
                type: 'select',
                optionsKey: 'SYS_CRM_YES_NO',
                minWidth: 150
            },
            {
                label: '中国区域数据治理剔除',
                prop: 'duplicateCheckFlag',
                type: 'select',
                optionsKey: 'SYS_CRM_YES_NO',
                minWidth: 150
            },
            {
                label: '备注',
                prop: 'remark',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                minWidth: 150
            },
            {
                label: '更新时间',
                prop: 'updateTime',
                minWidth: 150
            }
            // {
            //     label: '重试次数',
            //     prop: 'pushCount',
            //     minWidth: 150
            // }
        ]
    },
    actions: {
        fixedWidth: 120,
        list: [
            {
                label: '新建客户',
                event: 'add',
                displayRule: (row) => {
                    return row.innerCustFlag === NO_INNER_CUST_FLAG && +row.linkMatchOms === +YES_LINK_MATCH_OMS;
                }
            }

        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6
};
