<template>
    <div class="costomer-group-manage">
        <Paper class="costomer-group-manage-paper">
            <list-layout-paper>
                <template slot="header-search" class="searchBox">
                    <search-box
                        ref="searchBox"
                        :search-key="config.name"
                        :fields="config.searchFields"
                        :showResetBtn="true"
                        @search-change="subTabTable.searchList"
                    />
                </template>
                <template slot="header-button">
                       <lotsButton
                        v-has="authorityCode.export"
                        type="primary"
                        size="mini"
                        title="导出">
                        <report-export
                            #default="{ submit }"
                            reportCode="REPORT_CRM_CUSTOMER_UNIT">
                            <span @click="handleExport(submit)">导出</span>
                        </report-export>
                    </lotsButton>
                    <lotsButton
                        type="primary"
                        size="mini"
                        title="刷新"
                        @click="refresh"
                        plain>
                        <span class="icon iconfont icon-refresh"></span>
                    </lotsButton>
                    <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                        <el-button
                        icon="iconfont icon-list_install"
                        type="primary"
                        size="mini"
                        :title="'列配置'"
                        @click="showColumnConfigDialog"
                        plain />
                    </el-tooltip>
                </template>
                <!-- 表格 -->
                <template v-slot:list="{ tableHeight }">
                    <table-box
                        ref="clientOrder"
                        :height="tableHeight"
                        v-loading="subTabTable.tableLoading"
                        :selection="config.selection"
                        :index="config.index"
                        :columns="config.tableData.columns"
                        @sort-change="handleSort"
                        :actions="config.actions"
                        @selection-change="handleSelectedRow"
                        @action-click="actionClick"
                        :rows="subTabTable.totalData">
                    </table-box>
                </template>
                <template slot="footer-buttons">
                     <lots-button type='primary' :loading="bottomLoading" @click="addActions(selectedRows,'bottom')">新建客户</lots-button>
                    </template>
                <template slot="footer">
                    <lots-pagination
                        @size-change="subTabTable.sizeChange"
                        :current-page.sync="subTabTable.pageNo"
                        @current-change="subTabTable.pageChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="subTabTable.pageTotal" >
                    </lots-pagination>
                </template>
            </list-layout-paper>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.tableData.columns"
                ref="groupColumnConfig"
                @header-change="config.tableData.columns = $event"
            />
        </Paper>
    </div>
</template>
<script>
import { ref, reactive, onMounted } from '@vue/composition-api';
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import { getCustomerGroupInfoPage, pushCustomerGroupInfo } from '@mdm/api/customer/customerGroup.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import columnConfig from '@/components/lots/columnConfig/Index';
import reportExport from '@/components/lots/reportExportPlugin';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import store from '@/store';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Message } from 'element-ui';
export default {
    name: 'customerGroup',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        lotsPagination,
        lotsButton,
        columnConfig,
        reportExport
    },
    props: {},
    // eslint-disable-next-line max-lines-per-function
    setup (props, setupContext) {
        const authorityCode = reactive(btnAuthority.customerGroup);
        const tenantCode = store.getters.currentTenant.tenantCode;
        const { user } = store.getters;
        const config = reactive(configStatic);
        class CustomerGroupClass extends AdvanceListClass {
            beforeGetList (condition) {
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(it => {
                        it.addloading = false;
                        return it;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const subTabTable = new CustomerGroupClass({
            Api: {
                listApi: getCustomerGroupInfoPage // 查询接口
            }
        });
        onMounted(async () => {
            // 设置默认3个月查询
            const start = dayjs().startOf('day').subtract(3, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            const queryParams = {
                markTime: [start, end]
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
        });
        const refresh = () => {
            subTabTable.getList();
        };
        const searchDataFormat = (condition) => {
            if (condition.markTime && condition.markTime.length) {
                condition.startTime = utils.formatDateTime(condition.markTime[0]);
                condition.endTime = utils.formatDateTime(condition.markTime[1]);
                Reflect.deleteProperty(condition, 'markTime');
            }
            condition.orderBy = orderBy.value || 'update_time';
            condition.orderByType = orderByType.value || 'desc';
            condition.userCode = user.userCode;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return condition;
        };
        const groupColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            groupColumnConfig.value.show(true);
        };
        const handleExport = async(submit) => {
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const { value } = subTabTable.searchModel;
            const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
            const requestResults = [];
            config.tableData.columns.forEach((item) => {
                if (!item.hiddenColumn) {
                    requestResults.push(item.label);
                }
            });
            const params = {
                ...searchDataFormat(newData),
                tenantCode: tenantCode,
                requestResults: requestResults.join(',')

            };
            Object.keys(params).forEach(v => {
                if (params[v] === undefined || params[v] === null || params[v] === '') {
                    delete params[v];
                }
            });
            submit(params);
        };
        const orderBy = ref('');
        const orderByType = ref('');
        const orderType = {
            ascending: 'asc',
            descending: 'desc'
        };
        // 排序
        const handleSort = ({ prop, order }) => {
            orderBy.value = prop.replace(/([A-Z])/g, '_$1').toLowerCase();
            orderByType.value = orderType[order];
            if (!order) {
                orderBy.value = '';
                orderByType.value = '';
            }
            subTabTable.getList();
        };
        const selectedRows = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selectedRows.value = selection;
        };
        const actionClick = (event, row, index) => {
            row['addloading'] = true;
            addActions([row]).finally(() => {
                row['addloading'] = false;
            });
        };
        const bottomLoading = ref(false);
        const addActions = (data, loading = '') => {
            if (!_.isArray(data)) return;
            if (!data.length) {
                return Message.warning('请选择数据再新建客户');
            }
            const params = data.map(it => it.id);
            if (loading) {
                bottomLoading.value = true;
            }
            return pushCustomerGroupInfo(params).then(res => {
                if (res && +res.code === 0) {
                    Message.success('新建客户成功');
                }
            }).finally(() => {
                bottomLoading.value = false;
                subTabTable.getList();
            });
        };
        return {
            config,
            subTabTable,
            searchDataFormat,
            refresh,
            groupColumnConfig,
            showColumnConfigDialog,
            authorityCode,
            handleExport,
            handleSort,
            handleSelectedRow,
            actionClick,
            bottomLoading,
            selectedRows,
            addActions
        };
    }
};
</script>

<style lang="less">
.costomer-group-manage {
    width: 100%;
    .costomer-group-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }
    .flex-layout .list-main {
        overflow: auto;
    }
    .flex-layout .table {
        overflow-y: auto;
    }
    .layout-content.costomer-group-manage-paper {
        padding: 0px 0px 0px;
    }
}
.list-layout .list-header .el-input {
    margin-left: 0;
}
</style>
